import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';


import LoadingData from './components/LoadingData';
import DetalleNota from './components/DetalleNota';
import OtrasNotas from './components/OtrasNotas';

import '../scss/web/blog.scss';

class BlogDetalle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            detalle: [],
            otras_notas: []
        }
    }

    obtenerNotas = () => {
        var id = this.props.match.params.id;
        axios.get(`${process.env.REACT_APP_API_URL}noticias/detalle/${id}`,)
        .then(res => {
            const resultados = res.data;

            this.setState({
                loading: false,
                detalle: resultados['detalle'],
                otras_notas: resultados['otras_notas']
            });

            console.log(this.state.otras_notas);
        });
    }

    componentDidMount() {
        this.obtenerNotas();
    }

    render() {
        return(
            <section className="blog-detalle">
                <div className="container-fluid w14">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-12 col-lg-8 m40 mb-lg-0 detalle">
                            {this.state.loading ? <LoadingData /> : <DetalleNota detalle={this.state.detalle} /> }
                        </div>
                        <div className="col-12 col-md-12 col-lg-4 pl-xl-5 otros">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 m15 m-sm-30 m-lg-15">
                                    <div className="card card-valoracion border-0 rounded-0">
                                        <div className="card-body text-center">
                                            <h4 className="mb-3">Obtén tu valoración y cotización en menos de 24 hrs</h4>
                                            <Link to="/cotiza" className="btn btn-rojo" style={{maxWidth: "240px"}}>Solicita</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 m30">
                                    <div className="card card-faqs border-0 rounded-0">
                                        <div className="card-body text-center">
                                            <h4 className="mb-3">Conoce nuestras Preguntas Frecuentes acerca del Injerto Capilar</h4>
                                            <Link to="/faqs" className="btn btn-rojo" style={{maxWidth: "240px"}}>Ver todos</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <h5 className="titulo-small text-uppercase">Otras notas</h5>
                                    <hr />

                                    {this.state.loading ? <LoadingData /> : <OtrasNotas listado={this.state.otras_notas} />  }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(BlogDetalle);