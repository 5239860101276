import React, {Component} from 'react';

class DetalleNota extends Component {
    render() {
        return(
            <>
                <img src={this.props.detalle.portada} alt="portada" className="w-100 m30 m-lg-50" />
                <h3 className="tlo m15">{this.props.detalle.titulo}</h3>

                <div dangerouslySetInnerHTML={{__html: this.props.detalle.contenido}} />
                {/* {document.getElementById('contenido').innerHTML = this.props.detalle.contenido} */}
            </>
        );
    }
}

export default DetalleNota;