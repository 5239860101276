import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Swal from 'sweetalert2'
class Contacto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nombre: "",
            apellido: "",
            telefono: "",
            email: "",
            mensaje: "",

            verificado: false,
            enviado: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    handleSubmit(ev) {
        ev.preventDefault();
        let form = document.querySelector(".validate");

        if (form.checkValidity() === false) {
            //Campos invalidos
            ev.preventDefault();
            ev.stopPropagation();
        } else {
            //TODO: Verificar captcha
            //Hacemos submit jeje
            if(this.state.verificado){
                this.setState({...this.state, sending: true});

                console.log(process.env.REACT_APP_API_URL);
                axios.post(`${process.env.REACT_APP_API_URL}contacto/send`, null, {params: this.state})
                .then(res => {
                    console.log(res.status);
                    if((res.status === 200) && (res.data.success)){
                        // Mostramos mensaje enviado
                        Swal.fire(
                            'Operación exitosa',
                            'Mensaje enviado',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Oops!',
                            'Lo sentimos, algo salió mal',
                            'error'
                        )
                    }
                    this.setState({...this.state, sending: false});
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Oops!',
                        'Lo sentimos, algo salió mal',
                        'error'
                    )
                    this.setState({...this.state, sending: false});
                });
            } else{
                Swal.fire(
                    'Oops...',
                    'Favor de verificar el captcha',
                    'warning'
                )
            }
        }

        form.classList.add('was-validated');
    }

    verifyCallback (response) {
        this.setState({...this.state, verificado: true});
    }

    showButton(){
        if(this.state.sending){
            return <button type="submit" className="btn disabled" style={{PointerEvent: "none"}}>Enviando....</button>
        }else{
            return <button type="submit" className="btn" onClick={this.handleSubmit}>Enviar</button>
        }
    }


    render () {
        return(
            <div className="section-7">
                <div className="container-fluid w14" data-aos="fade">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-5 col-lg-4 m40 mb-md-0">
                            <h3 className="titulo m30">Contáctanos</h3>
                            <p className="text-white mb-0">Lunes a Viernes - 8:30 - 13:30 16:30 - 19:30</p>
                            <p className="text-white mb-0">Sábado 9:00 - 14:00</p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-7">
                            <form className="validate" noValidate>
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6 m30 m-lg-50">
                                        <input type="text" placeholder="Nombre" className="form-control mb-2" onChange={(el) => {this.setState({...this.state, nombre: el.target.value})}} required />
                                        <input type="text" placeholder="Apellido" className="form-control mb-2" onChange={(el) => {this.setState({...this.state, apellido: el.target.value})}} required />
                                        <input type="text" placeholder="Teléfono" className="form-control mb-2" onChange={(el) => {this.setState({...this.state, telefono: el.target.value})}} required />
                                        <input type="email" placeholder="E-mail" className="form-control" onChange={(el) => {this.setState({...this.state, email: el.target.value})}} required />
                                    </div>
                                    <div className="col-12 col-md-6 m30 m-lg-50">
                                        <textarea name="comentarios" placeholder="Comentarios" rows="7" className="form-control" onChange={(el) => {this.setState({...this.state, mensaje: el.target.value})}} required ></textarea>
                                    </div>
                                    <div className="col-12 col-md-6 m30">
                                        <div className="text-center d-flex align-items-center justify-content-center">
                                            <ReCAPTCHA
                                                sitekey="6LdocU4bAAAAAPli4AGjxERa6OkEJVuemFA89Dyb"
                                                onChange={this.verifyCallback}
                                                size="normal"
                                                badge="inline"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 m30 text-center text-md-right">
                                        {this.showButton()}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contacto;