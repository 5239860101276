import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Equipo from './components/Equipo';

import SwiperCore, { Autoplay, Navigation, A11y } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/navigation/navigation.scss';

import '../scss/web/nosotros.scss';

import foto from '../img/nosotros/foto.jpg';
import equipo from '../img/nosotros/equipo.jpg';
import equipo_1 from '../img/nosotros/equipo/1.jpg';
import equipo_2 from '../img/nosotros/equipo/2.jpg';
import equipo_3 from '../img/nosotros/equipo/3.jpg';
import equipo_4 from '../img/nosotros/equipo/4.jpg';
import equipo_5 from '../img/nosotros/equipo/5.jpg';
import equipo_6 from '../img/nosotros/equipo/6.jpg';
import equipo_7 from '../img/nosotros/equipo/7.jpg';
import base from '../img/nosotros/cirujia.jpg';
import slide_1 from '../img/nosotros/slide/1.jpg';
import slide_2 from '../img/nosotros/slide/2.jpg';
import slide_3 from '../img/nosotros/slide/3.jpg';
import slide_4 from '../img/nosotros/slide/4.jpg';
import slide_5 from '../img/nosotros/slide/5.jpg';
import slide_6 from '../img/nosotros/slide/6.jpg';
import slide_7 from '../img/nosotros/slide/7.jpg';
import slide_8 from '../img/nosotros/slide/8.jpg';
import slide_9 from '../img/nosotros/slide/9.jpg';
import slide_10 from '../img/nosotros/slide/10.jpg';
import slide_11 from '../img/nosotros/slide/11.jpg';
import logo from '../img/nosotros/logo.png';

SwiperCore.use([Autoplay, Navigation, A11y]);
class Nosotros extends Component {
    constructor(props) {
        super(props);

        const equipos = [
            {
                portada: equipo_1,
                nombre: "LE Gloria Gonzalez Villanueva",
                puesto: "Médico Cirujano"
            },
            {
                portada: equipo_2,
                nombre: "LE André Canul Pech",
                puesto: "Médico Cirujano"
            },
            {
                portada: equipo_3,
                nombre: "LEQ Juanita Charles Castillo",
                puesto: "Médico Cirujano"
            },
            {
                portada: equipo_4,
                nombre: "Dr. Alejandro J. Medina García",
                puesto: "Médico Cirujano"
            },
            {
                portada: equipo_5,
                nombre: "Karla Castañeda Espinosa",
                puesto: "Asistente Médico"
            },
            {
                portada: equipo_6,
                nombre: "Dra. Lizbeth Pinelo Martin",
                puesto: "Médico Cirujano"
            },
            {
                portada: equipo_7,
                nombre: "LEQ Silemy Yazuri Tek Miam",
                puesto: "Médico Cirujano"
            }
        ];

        this.state = {
            equipo: equipos
        }
    }

    render(){
        return(
            <section className="nosotros">
                {/* Informacion y fotografia */}
                <div className="section-1">
                    <div className="container-fluid w16" data-aos="fade">
                        <div className="row justify-content-">
                            <div className="col-12 col-md-12 col-lg-6 col-xl-5 px-0">
                                <img src={foto} alt="Foto" />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6 col-xl-7 pl-xl-5 pr-xl-5 py-5 d-flex align-items-center justify-content-center">
                                <div className="w-lg-90 w-xl-80">
                                    <h3 className="titulo mb-0">Dr. Alejandro J. Medina</h3>
                                    <p className="m40"><small>Cirujano plástico certificado | Ced. Prof. 5487535 | Ced. Esp. 8996896</small></p>
                                    <ul className="pl-3">
                                        <li><p className="mb-0">Carrera de Medicina en la Universidad de Monterrey ( 2000 )</p></li>
                                        <li><p className="mb-0">Internado de Pregrado en el Saint Luke’s Episcopal Hospital en Houston, Texas recibiendo mención honorifica, para así obtener la Licenciatura de Médico Cirujano y Partero. (2005)</p></li>
                                        <li><p className="mb-0">Especialidad en Cirugía Plástica, Reconstructiva y Estética en el Hospital Militar Sheba, Tel Hashomer en Tel aviv, Israel, completando su conocimiento rotando en los distintos servicios complementarios del propio hospital.</p></li>
                                        <li><p className="mb-0">Continuó su experiencia quirúrgica estética con el Dr. Giovanni Botti en Salò, Italia. Participando asi mismo en congresos de la Sociedad Italiana de Cirugía Plástica.</p></li>
                                        <li><p className="mb-0">Realizó misiones humanitarias junto con el Prof. Eyal Winkler, en Cusco, Perú en el 2012 y en la Cd. de Guatemala en el 2013, realizando operaciones de corrección de Labio y Paladar hendido, y quemaduras en niños.</p></li>
                                        <li><p className="mb-0">Miembro activo de la "The Israeli Society of Plastic Surgery”</p></li>
                                        <li><p className="mb-0">Miembro de la ISHRS (International Society of Hair Restoration Surgery)</p></li>
                                        <li><p className="mb-0">Certificación en trasplante capilar en el hospital Sheba, Tel Hashomer en Tel Aviv (2015)</p></li>
                                        <li><p className="mb-0">Capacitación en nuevas técnicas en la clínica Natural Hair Turkey en Estambul, Turquía (2021)</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <img src={equipo} alt="equipo" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Equipo */}
                <div className="section-2">
                    <div className="container-fluid w16" data-aos="fade">
                        <h3 className="titulo text-center m40 m-lg-80">Equipo</h3>
                        <div className="row">
                            {this.state.equipo.map((integrante, idx) => {
                                return(
                                    <Equipo
                                        foto={integrante.portada}
                                        nombre={integrante.nombre}
                                        puesto={integrante.puesto}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Banner de valoracion */}
                <div className="section-3">
                    <div className="container" data-aos="fade">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <h3 className="titulo text-white mb-0">Obtén tu valoración y cotización en menos de 24 hrs</h3>
                            </div>
                            <div className="col-12 col-md-6 text-center text-md-right">
                                <Link to="/cotiza" className="btn btn-rojo" style={{maxWidth: "240px"}}>Solicitar</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Slide */}
                <div className="section-4">
                    <div className="container-fluid w16 px-0" data-aos="fade">
                        <Swiper
                            spaceBetween={0}
                            speed={1500}
                            effect={'fade'}
                            navigation
                            autoplay={{
                                disableOnInteraction: false
                            }}
                            loop={true}
                        >
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_1})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_2})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_3})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_4})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_5})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_6})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_7})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_8})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_9})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_10})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_11})`}}><img src={base} alt="base" /></div> </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

                {/* Link a otro website */}
                <div className="section-5 text-center">
                    <div data-aos="fade">
                        <h3 className="m30 text-white">Otros servicios de Amederi</h3>
                        <a href="https://amederi.com/" target="_blank" rel="noreferrer">
                            <img src={logo} alt="Amederi" className="w-max-100" />
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Nosotros;