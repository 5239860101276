import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import logo from '../../img/header/logo.svg';
import icon_facebook from '../../img/header/face.svg';
import icon_instagram from '../../img/header/inst.svg';

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            open: false
        }
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        return(
            <header>
                <nav className="nav-movil d-lg-none">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <Link to="/">
                                    <img src={logo} alt="HairClinic" className="w-max-100" />
                                </Link>
                            </div>
                            <div className="col-3 text-right">
                                <div className={`menu menu-3 ${(this.state.open) ? 'active' : ''}`} onClick={this.toggle}>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`sidebar ${this.state.open ? 'active' : ''}`}>
                        <div className="container">
                            <ul className="list-unstyled text-center">
                                <li className="mb-4"> <NavLink exact activeClassName="active" to="/"> Inicio </NavLink> </li>
                                <li className="mb-4"> <NavLink exact activeClassName="active" to="/injerto">Injerto Capilar </NavLink> </li>
                                <li className="mb-4"> <NavLink exact activeClassName="active" to="/nosotros">Nosotros </NavLink> </li>
                                <li className="mb-4"> <NavLink exact activeClassName="active" to="/cotiza">Cotiza </NavLink> </li>
                                <li className="mb-4"> <NavLink activeClassName="active" to="/blog">Blog </NavLink> </li>
                                <li className="mb-4"> <NavLink exact activeClassName="active" to="/faqs">Preguntas Frecuentes </NavLink> </li>
                                <li className="mb-4"> <NavLink exact activeClassName="active" to="/clientes">Clientes </NavLink> </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <nav className="nav-desk d-none d-lg-block">
                    <div className="container-fluid w16 justify-content-between">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-3">
                                <Link to="/">
                                    <img src={logo} alt="HairClinic" className="w-max-100" />
                                </Link>
                            </div>
                            <div className="col-8 col-xl-7">
                                <ul className="d-flex align-items-center justify-content-between list-unstyled">
                                    <li> <NavLink exact activeClassName="active" to="/"> Inicio </NavLink> </li>
                                    <li> <NavLink exact activeClassName="active" to="/injerto">Injerto Capilar </NavLink> </li>
                                    <li> <NavLink exact activeClassName="active" to="/nosotros">Nosotros </NavLink> </li>
                                    <li> <NavLink exact activeClassName="active" to="/cotiza">Cotiza </NavLink> </li>
                                    <li> <NavLink activeClassName="active" to="/blog">Blog </NavLink> </li>
                                    <li> <NavLink exact activeClassName="active" to="/faqs">Preguntas Frecuentes </NavLink> </li>
                                    <li> <NavLink exact activeClassName="active" to="/clientes">Clientes </NavLink> </li>
                                </ul>
                            </div>
                            <div className="col-1 text-right">
                                <Link to="/" target="_blank"><img src={icon_facebook} alt="Facebook" className="mr-3" /></Link>
                                <Link to="/" target="_blank"><img src={icon_instagram} alt="Instagram" /></Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;