import React, {Component} from 'react';
import axios from 'axios';

import SliderBeforeAfter from './components/SliderBeforeAfter';
import LoadingData from './components/LoadingData';
import SinResultados from './components/SinResultados';
import ResultadosBlog from './components/ResultadosBlog';

import '../scss/web/blog.scss';

import portada from '../img/blog/portada.jpg';
import before_1 from '../img/blog/clientes/1.jpg';
import after_1 from '../img/blog/clientes/2.jpg';
import before_2 from '../img/blog/clientes/3.jpg';
import after_2 from '../img/blog/clientes/4.jpg';

class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notas: [],
            notas_num: 0,
            loading: true,
            pagina: 1,
            inputBuscador: "",
            buscador: ""
        }

        this.masNoticias = this.masNoticias.bind(this);
        this.buscarNota = this.buscarNota.bind(this);
    }

    obtenerNoticias = () => {
        var data = {};

        this.setState({pagina: this.state.pagina + 1});
        data = {page: this.state.pagina}

        axios.get(`${process.env.REACT_APP_API_URL}noticias`, {params: data})
        .then(res => {
            const resultados = res.data;

            this.setState({
                loading: false,
                notas: [...this.state.notas, ...resultados],
                notas_num: res.data.length
            });
        });
    }

    obtenerNoticiasAll = () => {
        axios.get(`${process.env.REACT_APP_API_URL}noticias`)
        .then(res => {
            const resultados = res.data;
            this.setState({notas: [], pagina: 1, loading: true});
            this.state.notas.splice();
            this.setState({
                loading: false,
                notas: resultados,
                notas_num: res.data.length
            });
        });
    }

    obtenerNoticiasBusqueda = () => {
        axios.get(`${process.env.REACT_APP_API_URL}noticias`, {params: {buscador: this.state.buscador}})
        .then(res => {
            const resultados = res.data;
            console.log(resultados);

            this.setState({
                loading: false,
                notas: resultados,
                notas_num: 0
            });
            console.log(this.state.notas_num);
        });
    }

    componentDidMount() {
        this.obtenerNoticias();
    }

    masNoticias = () => {
        this.obtenerNoticias();
    }

    buscarNota = (event) => {
        this.setState({ loading: true, buscador: event.target.value });
        if(event.target.value === "") {
            this.setState({notas: [], pagina: 1, loading: true});
            this.obtenerNoticiasAll();
        }
        this.obtenerNoticiasBusqueda();
    }

    render(){
        return(
            <section className="blog">
                <div className="blog-1">
                    <div className="container-fluid w16 px-0" data-aos="fade">
                        <img src={portada} alt="baner" className="w-max-100" />
                    </div>
                    <div className="bg-dark py-4">
                        <div className="container" data-aos="fade">
                            <h3 className="titulo">Blog {this.state.buscador}</h3>
                        </div>
                    </div>
                </div>

                <div className="blog-2 py-4">
                    <div className="container" data-aos="fade">
                        <div className="m40"></div>
                        {/* <form className="text-right m40" id="">
                            <input type="search" placeholder="Buscar" className="form-control ml-auto mr-0 rounded-0" style={{maxWidth: "360px"}} onChange={this.buscarNota} value={this.state.buscador} />
                        </form> */}
                        {/* <BuscadorBlog inputBuscador={this.state.inputBuscador} buscarNotaOnChange={this.buscarNota} /> */}

                        {this.state.loading ? <LoadingData /> : (this.state.notas.length > 0) ? <ResultadosBlog resultados={this.state.notas} /> : <SinResultados />}

                        {this.state.notas_num === 6 ?
                            <div className="text-center">
                                <button className="btn btn-rojo-only my-5" style={{maxWidth: "240px"}} onClick={this.masNoticias}>Ver mas</button>
                            </div> : ""
                        }
                    </div>
                </div>

                <div className="banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 m30 mb-md-0">
                                <h3 className="titulo text-white mb-0">Obtén tu valoración y cotización en menos de 24 hrs</h3>
                            </div>
                            <div className="col-12 col-md-6 text-center text-md-right">
                                <button className="btn btn-rojo" style={{maxWidth: "240px"}}>Solicitar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="blog-3">
                    <div className="container">
                        <h3 className="titulo text-center m40">Nuestros Clientes</h3>
                        <div className="row justify-content-around m40">
                            <div className="col-12 col-md-6 col-lg-5 m30 mb-md-0 text-center">
                                <div className="d-inline-block">
                                    <SliderBeforeAfter
                                        img_before={before_1}
                                        img_after={after_1}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 text-center">
                                <div className="d-inline-block">
                                    <SliderBeforeAfter
                                        img_before={before_2}
                                        img_after={after_2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Blog;