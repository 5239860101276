import React, { Component } from 'react';
import { ImgComparisonSlider } from '@img-comparison-slider/react';

class SliderBeforeAfter extends Component {
    render() {
        return(
            <ImgComparisonSlider>
                <img slot="first" src={this.props.img_before} className="w-100" alt="Base" />
                <img slot="second" src={this.props.img_after} className="w-100" alt="Base" />
            </ImgComparisonSlider>
        );
    }
}

export default SliderBeforeAfter;