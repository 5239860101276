import React, {Component} from 'react';

import CardBlog from './CardBlog';

import '../../css/loading.css';


class ResultadosBlog extends Component {
    render(){
        return (
            <div className="row">
                {this.props.resultados.map((nota, idx) => {
                    return(
                        <div className="col-12 col-md-6 col-lg-4 m30" key={idx}>
                            <CardBlog
                            portada={nota.portada}
                            fecha={nota.fecha}
                            titulo={nota.titulo}
                            descripcion_corta={nota.descripcion_corta}
                            url={`/blog/detalle/${nota.url}`}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default ResultadosBlog;