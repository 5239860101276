import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import SliderBeforeAfter from './components/SliderBeforeAfter';

import '../scss/web/clientes.scss';

import ab_1 from '../img/clientes/1.jpg';
import ab_2 from '../img/clientes/2.jpg';
import ab_3 from '../img/clientes/3.jpg';
import ab_4 from '../img/clientes/4.jpg';
import ab_5 from '../img/clientes/5.jpg';
import ab_6 from '../img/clientes/6.jpg';
import ab_7 from '../img/clientes/7.jpg';
import ab_8 from '../img/clientes/8.jpg';
import ab_9 from '../img/clientes/9.jpg';
import ab_10 from '../img/clientes/10.jpg';
import ab_11 from '../img/clientes/11.jpg';
import ab_12 from '../img/clientes/12.jpg';

class Clientes extends Component {
    render(){
        return(
            <section className="clientes pb-0">
                <div className="container-fluid w16" data-aos="fade">
                    <h3 className="text-center titulo m40">Nuestros Clientes</h3>

                    <div className="row text-center">
                        <div className="col-12 col-md-6 col-lg-4 m30 m-lg-40">
                            <SliderBeforeAfter
                                img_before={ab_1}
                                img_after={ab_2}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 m30 m-lg-40">
                            <SliderBeforeAfter
                                img_before={ab_3}
                                img_after={ab_4}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 m30 m-lg-40">
                            <SliderBeforeAfter
                                img_before={ab_5}
                                img_after={ab_6}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 m30 m-lg-40">
                            <SliderBeforeAfter
                                img_before={ab_7}
                                img_after={ab_8}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 m30 m-lg-40">
                            <SliderBeforeAfter
                                img_before={ab_9}
                                img_after={ab_10}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 m30 m-lg-40">
                            <SliderBeforeAfter
                                img_before={ab_11}
                                img_after={ab_12}
                            />
                        </div>
                    </div>
                </div>

                <div className="banner">
                    <div className="container" data-aos="fade">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 m30 mb-md-0">
                                <h3 className="titulo text-white mb-0">Obtén tu valoración y cotización en menos de 24 hrs</h3>
                            </div>
                            <div className="col-12 col-md-6 text-center text-md-right">
                                <Link to="/cotiza" className="btn btn-rojo" style={{maxWidth: "240px"}}>Solicitar</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Clientes;