import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Faq from './components/Faq';

import '../scss/web/faqs.scss';

import foto from '../img/faqs/foto.jpg';

class Faqs extends Component {
    constructor(props) {
        super(props);

        const faqs = [
            {
                titulo: "¿Cuanto tarda el proceso?",
                descripcion: "El proceso desde que se entra al consultorio hasta que se sale puede ser de 8 a 10 horas dependiendo del tamaño del área a tratar."
            },
            {
                titulo: "¿Cual técnica me conviene?",
                descripcion: "Dependiendo del área a cubrir, si es grande o pequeña, y de la calidad de la zona donadora, se definirá la técnica ideal. Actualmente manejamos las técnicas FUT, FUSS y combinadas, de las cuales puedes leer más aquí."
            },
            {
                titulo: "¿Se me va a volver a caer el cabello?",
                descripcion: "El cabello trasplantado no se caerá. En general mantendrás el nivel de caída normal de alguien que no padece de ningún tipo de alopecia o condición si sigues tu tratamiento posterior con disciplina."
            },
            {
                titulo: "¿Que cuidados debo tener después?",
                descripcion: "Después del proceso no debes lavarte el cabello por 72 hrs. Descarga las indicaciones post operatorias aqui."
            },
            {
                titulo: "¿Al cuanto tiempo mi cabello tendrá su apariencia final?",
                descripcion: "El resultado final se logra ver al año del procedimiento, aunque desde los 6 meses podrás comenzar a ver cambios."
            },
            {
                titulo: "¿Todos pueden realizarse el procedimiento?",
                descripcion: "Si, pero todo va a depender de la calidad y la zona donadora."
            },
            {
                titulo: "¿Pueden haber efectos secundarios?",
                descripcion: "Los efectos secundarios son muy raros y se logran evitar siguiendo las indicaciones post operatorias. Algo usual es tener costras que durarán de 1 a 2 semanas y se caerán solas."
            },
            {
                titulo: "¿Cuánto dura el resultado?",
                descripcion: "El injerto tendrá el mismo tiempo de vida que el resto de tu cabello en base al cuidado y estilo de vida que lleves. Es como si siempre hubiera estado ahí."
            }
        ];

        this.state = {
            faqs: faqs
        }
    }

    render() {
        return (
            <section className="faqs">
                <div className="faqs-1">
                    <div className="container-fluid w16" data-aos="fade">
                        <div className="row align-items-center m40">
                            <div className="col-12 col-md-6 m30 mb-md-0 pt-5 pb-5 pr-lg-5">
                                <div className="mt-5"></div>
                                <h3 className="titulo m30">Preguntas frecuentes</h3>
                                <h5 className="titulo-small">¿Es doloroso?</h5>
                                <p className="mb-0">El proceso se realiza con anestesia local por lo cual las molestias son mínimas. Aplicar la anestesia podría ser lo más incómodo pero es momentáneo.</p>
                                <div className="mb-5"></div>
                            </div>
                            <div className="col-12 col-md-6 px-0">
                                <img src={foto} alt="foto" className="w-max-100" />
                            </div>
                        </div>
                    </div>

                    <div className="container" data-aos="fade">
                        {this.state.faqs.map((faq, idx) => {
                            if(idx > 2) return null;

                            return(
                                <Faq
                                    align={idx%2 === 0 ? 'text-left' : 'text-right'}
                                    titulo={faq.titulo}
                                    info={faq.descripcion}
                                    id={idx}
                                />
                            )
                        })}
                    </div>

                    <div className="banner m40 m-lg-80">
                        <div className="container" data-aos="fade">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 m30 mb-md-0">
                                    <h3 className="titulo text-white mb-0">Obtén tu valoración y cotización en menos de 24 hrs</h3>
                                </div>
                                <div className="col-12 col-md-6 text-center text-md-right">
                                    <Link to="/cotiza" className="btn btn-rojo" style={{maxWidth: "240px"}}>Solicitar</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container" data-aos="fade">
                        {this.state.faqs.map((faq, idx) => {
                            if(idx < 3) return null;

                            return(
                                <Faq
                                    align={idx%2 === 0 ? 'text-left' : 'text-right'}
                                    titulo={faq.titulo}
                                    info={faq.descripcion}
                                    id={idx}
                                />
                            )
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Faqs;