import React, {Component} from 'react';

import FormCotiza from './components/Cotiza';

import '../scss/web/cotiza.scss';
import '../scss/components/cotiza.scss';

import baner from '../img/baner.jpg';

class Cotiza extends Component {
    render() {
        return(
            <section className="cotizar">
                <div className="cotizar-1">
                    <div className="container-fluid w16 px-0" data-aos="fade">
                        <img src={baner} alt="baner" className="w-max-100" />
                    </div>
                </div>

                <FormCotiza bg="dark-white" />
            </section>
        );
    }
}

export default Cotiza;