import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';

class CustomFileInput extends Component {
    state = {
        file: ""
    }

    handleFiles = files => {
        this.setState({
            file: files.base64
        });
    }

    render () {
        return (
            <div>
                <div className="files mb-2">
                    <div className="bg bg-size-contain" style={{backgroundImage: `url(${this.state.file})`}} />
                    <ReactFileReader handleFiles={this.handleFiles} base64={true}>
                        <button type="button" className='btn shadow-none p-0'>
                            <img src={this.props.icon_upload} alt="Base" className="w-max-100"/>
                        </button>
                    </ReactFileReader>
                </div>
                <p className="text-white text-center mb-0">{this.props.title}</p>
            </div>
        );
    }
}

export default CustomFileInput;