import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Swal from 'sweetalert2'

import CustomFileInput from './CustomFileInput';

import '../../scss/components/cotiza.scss';

import icon_file_1 from '../../img/cotizador/01.jpg';
import icon_file_2 from '../../img/cotizador/02.jpg';
import icon_file_3 from '../../img/cotizador/03.jpg';
import icon_file_4 from '../../img/cotizador/04.jpg';
import icon_file_5 from '../../img/cotizador/05.jpg';

class Cotiza extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nombre: "",
            apellido: "",
            telefono: "",
            email: "",
            mensaje: "",

            verificado: false,
            enviado: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    handleSubmit(ev) {
        ev.preventDefault();
        let form = document.querySelector(".validate");

        if (form.checkValidity() === false) {
            //Campos invalidos
            ev.preventDefault();
            ev.stopPropagation();
        } else {
            //TODO: Verificar captcha
            //Hacemos submit jeje
            if(this.state.verificado){
                this.setState({...this.state, sending: true});

                let f1 = document.querySelector('.file_1 input[type="file"]').files[0];
                let f2 = document.querySelector('.file_2 input[type="file"]').files[0];
                let f3 = document.querySelector('.file_3 input[type="file"]').files[0];
                let f4 = document.querySelector('.file_4 input[type="file"]').files[0];
                let f5 = document.querySelector('.file_5 input[type="file"]').files[0];

                const formData = new FormData();
                formData.append('nombre', this.state.nombre+''+this.state.apellido);
                formData.append('email', this.state.email);
                formData.append('telefono', this.state.telefono);
                formData.append('mensaje', this.state.mensaje);
                formData.append('file_1', f1);
                formData.append('file_2', f2);
                formData.append('file_3', f3);
                formData.append('file_4', f4);
                formData.append('file_5', f5);

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                axios.post(`${process.env.REACT_APP_API_URL}cotizacion/send`, formData, config)
                .then(res => {
                    console.log(res.status);
                    if((res.status === 200) && (res.data.success)){
                        // Mostramos mensaje enviado
                        Swal.fire(
                            'Operación exitosa',
                            'Mensaje enviado',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Oops!',
                            'Lo sentimos, algo salió mal',
                            'error'
                        )
                    }
                    this.setState({...this.state, sending: false});
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Oops!',
                        'Lo sentimos, algo salió mal',
                        'error'
                    )
                    this.setState({...this.state, sending: false});
                });
            } else{
                Swal.fire(
                    'Oops...',
                    'Favor de verificar el captcha',
                    'warning'
                )
            }
        }

        form.classList.add('was-validated');
    }

    verifyCallback (response) {
        this.setState({...this.state, verificado: true});
    }

    showButton(){
        if(this.state.sending){
            return <button type="submit" className="btn disabled" style={{PointerEvent: "none"}}>Enviando....</button>
        }else{
            return <button type="submit" onClick={this.handleSubmit} className="btn">Enviar</button>
        }
    }


    render() {
        return(
            <div className={`cotiza ${this.props.bg}`}>
                <div className="container-fluid w15" data-aos="fade">
                    <h3 className="titulo m30">Cotiza con nosotros</h3>
                    <form className="validate" noValidate>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 col-lg-4 bg-darkc m80 mb-md-0">
                                <input type="text" placeholder="Nombre" className="form-control mb-2" onChange={(el) => {this.setState({...this.state, nombre: el.target.value})}} required />
                                <input type="text" placeholder="Apellidos" className="form-control mb-2" onChange={(el) => {this.setState({...this.state, apellido: el.target.value})}} required />
                                <input type="text" placeholder="Teléfono" className="form-control mb-2" onChange={(el) => {this.setState({...this.state, telefono: el.target.value})}} required />
                                <input type="email" placeholder="Correo" className="form-control mb-2" onChange={(el) => {this.setState({...this.state, email: el.target.value})}} required />
                                <textarea name="comentarios" placeholder="Comentarios" onChange={(el) => {this.setState({...this.state, mensaje: el.target.value})}} rows="5" className="form-control" ></textarea>
                            </div>
                            <div className="col-12 col-md-7 col-lg-8 pl-lg-5">
                                <p className="text-white m15">Para una cotización exacta favor de enviar las siguientes fotos tuyas: Haz clic en cada una y adjunta la foto correspondiente.</p>

                                <div className="row justify-content-around m20">
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 m20 file_1">
                                        <CustomFileInput icon_upload={icon_file_1} title={'Frente'} />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 m20 file_2">
                                        <CustomFileInput icon_upload={icon_file_2} title={'Perfil derecho'} />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 m20 file_3">
                                        <CustomFileInput icon_upload={icon_file_3} title={'Superior'} />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 m20 file_4">
                                        <CustomFileInput icon_upload={icon_file_4} title={'Perfil izquierdo'} />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 m20 file_5">
                                        <CustomFileInput icon_upload={icon_file_5} title={'Reverso'} />
                                    </div>
                                </div>

                                <p className="text-center text-white m30">Tu fotos son confidenciales. Se usarán sólo para la cotización.</p>

                                <div className="text-center d-flex align-items-center justify-content-center m20">
                                    <ReCAPTCHA
                                        sitekey="6LdrSYIaAAAAABnZA5ttwo4r_XSOh_LJYKoZ8r1k"
                                        onChange={this.verifyCallback}
                                        size="normal"
                                        badge="inline"
                                    />
                                </div>

                                <div className="text-center">
                                    {this.showButton()}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Cotiza;