import React, {Component} from 'react';

import '../../css/loading.css';

class LoadingData extends Component {
    render(){
        return (
            <div className="d-flex align-items-center justify-content-center" style={{minHeight: "300px", flexDirection: "column"}}>
                <span className="loader text-center">
                    <span className="loader-inner"></span>
                </span>
                <p className="mt-4">Cargando ...</p>
            </div>
        );
    }
}

export default LoadingData;