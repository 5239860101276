import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import SliderBeforeAfter from './components/SliderBeforeAfter';

import '../scss/web/injerto.scss';

import persona_1 from '../img/injerto/foto1.jpg';
import persona_2 from '../img/injerto/foto2.jpg';
import before_1 from '../img/injerto/clientes/01-antes.jpg';
import after_1 from '../img/injerto/clientes/02-des.jpg';
import before_2 from '../img/injerto/clientes/03-antes.jpg';
import after_2 from '../img/injerto/clientes/04-des.jpg';

class Injerto extends Component {
    render(){
        return (
            <section className="injerto">
                {/* Informacion y fotografia */}
                <div className="section-1">
                    <div className="container-fluid w16" data-aos="fade">
                        <div className="row">
                            <div className="col-12 col-md-6 pl-lg-5 pr-lg-5 py-5">
                                <h3 className="titulo m30">Injerto Capilar</h3>
                                <h5 className="titulo-small">Técnica FUE (Follicular Unit Extraction)</h5>
                                <p className="m30 m-lg-40">Esta técnica se realiza con anestesia local, sin dolor y sin bisturí, suturas o cortes.</p>
                                <p className="font-weight-bold">Paso 1</p>
                                <p className="m30 m-lg-40">Se inicia extrayendo los folículos necesarios del área donadora, generalmente siendo ésta el área de la nuca. Los pequeños orificios de apenas un milímetro de diámetro se cierran solos en tres días sin dejar ningún tipo de cicatriz. El cabello de esta área volverá a crecer de manera normal.</p>
                                <p className="font-weight-bold">Paso 2</p>
                                <p className="m30 m-lg-40">Los injertos son clasificados y revisados para posteriormente ser trasplantados al área de alopecia.</p>
                                <p className="font-weight-bold">Paso 3</p>
                                <p className="m30 m-lg-40">Mediante la técnica DHI (Direct Hair Implant) usamos instrumentos de vanguardia de implantación como lo son las Choi Pens, y con esto obtener un resultado natural y sin cicatrices.</p>
                                <p>En esta técnica podemos trasplantar desde 1,500 a 3,500 injertos, dando un total de 4 mil a 8 mil cabellos, y dependiendo la cantidad, se realiza en un tiempo de 6 a 10 horas.</p>
                            </div>
                            <div className="col-12 col-md-6 px-0">
                                <img src={persona_1} alt="Persona" />
                            </div>
                            <div className="col-12 col-md-6 col-lg-7 pt-5">
                                <h5 className="titulo-small">Técnica FUSS (Follicular Unit Strip Surgery)</h5>
                                <p className="m30 m-lg-60">También llamada técnica de la tira de cuero cabelludo. Se realiza con anestesia local, el dolor es mínimo.</p>
                                <p className="mb-0">Paso 1</p>
                                <p className="mb-0">Se retira una tira de piel cabelluda del área de la nuca, de un centímetro de ancho aproximadamente y tan largo como el ancho de oreja a oreja, puede ser menos dependiendo del número de injertos necesarios.</p>
                                <p className="m30 m-lg-60">Se realiza con bisturí y suturas que se retiran a los 10 días. El cabello que crece dentro de la línea de la cicatriz la cubrirá totalmente por lo que será casi imposible de percibir, incluso con el cabello corto. </p>
                                <p className="mb-0">Paso 2</p>
                                <p className="m30 m-lg-60">Los injertos son clasificados y revisados para posteriormente ser trasplantados al área de alopecia.</p>
                                <p className="mb-0">Paso 3</p>
                                <p className="m30 m-lg-60">Mediante la técnica DHI (Direct Hair Implant) usamos instrumentos de vanguardia de implantación como lo son las Choi Pens, y con esto obtener un resultado natural y sin cicatrices.</p>
                                <p className="mb-0">Con esta técnica se pueden trasplantar cantidades de 1000 a 2000 injertos en un tiempo de 6 a 10 horas.</p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 pt-5">
                                <h5 className="titulo-small">Técnica combinada</h5>
                                <p className="m30 m-lg-60">Que es la combinación de ambas técnicas FUSS y FUE en una sola sesión y se trasplantan desde 3,500 a 4,500 cabellos que son un promedio de 1,500 a 2,500 injertos en una sola sesión para cubrir más áreas.</p>
                                <h5 className="titulo-small">¿Qué factores determinan cual se va a usar?</h5>
                                <p className="m30 m-lg-60">La proporción del área a tratar (donde se aplicará el trasplante) y la calidad del área donadora (de donde se obtendrán los folículos)</p>
                                <h5 className="titulo-small">Plasma rico en plaquetas PRP</h5>
                                <p className=" m30">Realizamos la técnica PRP (Plasma Rico en Plaquetas) para fortalecer con factores de crecimiento obtenidos del plasma del mismo paciente.</p>
                                <p className="mb-0">Se recomiendan sesiones mensuales después del trasplante, lo que ayudará a fortalecer y obtener un mejor resultado.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Proceso */}
                <div className="section-2">
                    <h3 className="titulo text-center m40" data-aos="fade">Proceso</h3>

                    <div className="container-fluid m50" data-aos="fade">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 col-lg-5 pl-lg-5 pr-lg-5 m40 mb-lg-0">
                                <ul className="mx-auto">
                                    <li>Enviar información por web y recibir cotización</li>
                                    <li>Agendar día de procedimiento (con anticipación)</li>
                                    <li>Suspender el uso de minoxidil (si se usa) por lo menos 7 días previos</li>
                                    <li>Mantenerse en ayunas 8 horas antes del procedimiento</li>
                                    <li>El día del procedimiento se marcará el área donadora y el área a recibir el injerto</li>
                                    <li>El procedimiento se realiza bajo anestesia local</li>
                                    <li>El primer paso será la toma de folículos del área donadora (nuca). Estarás en una cama de exploración, boca abajo. Esta parte del procedimiento dura de 3 a 4 horas.</li>
                                    <li>Después de esta parte del proceso ofrecemos un descanso de 30 minutos en el cual te proporcionaremos alimentos.</li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 pl-lg-5 pr-lg-5">
                                <ul className="m50 mx-auto">
                                    <li>El segundo paso es el trasplantar los folículos al área a injertar, en esta parte del proceso estarás boca arriba y podrás incluso puedes mirar tu serie favorita o escuchar música ya que esta parte del proceso dura de 4 a 5 horas.</li>
                                    <li>Una vez terminado el proceso se hace una limpieza del área y se aplica un vendaje que no se retirará por 3 días.</li>
                                    <li>A partir del tercer día que se quita el vendaje se comenzará un tratamiento (suplemento tomado, shampooo y spray especial) que se continuará durante un año.</li>
                                </ul>

                                <div className="text-center">
                                    <button className="btn btn-rojo">Descarga las indicaciones post operatorias aquí (PDF descargable)</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container" data-aos="fade">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-6 col-lg-5">
                                <h3 className="titulo text-white">Obtén tu valoración y cotización en menos de 24 hrs</h3>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 text-center">
                                <Link to="/cotiza" className="btn btn-rojo" style={{maxWidth: "240px"}}>Solicitar</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Candidatos */}
                <div className="section-3">
                    <div className="container-fluid w16" data-aos="fade">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-6 px-0 m30 mb-md-0">
                                <img src={persona_2} alt="Foto 2" className="w-100" />
                            </div>
                            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                <div className="w-lg-90 w-xl-70">
                                    <h3 className="titulo m30">¿Quién es candidato?</h3>
                                    <p className="mb-0">El 90% de las personas con alopecia androgenética. Es necesario tener una zona donante de donde poder extraer los folículos, y un estado de salud adecuado. Jamás se debe realizar un trasplante si la zona donante es pobre porque una vez extraídos los folículos, en este punto ya no crece el pelo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Link a faqs */}
                <div className="section-4">
                    <div className="container" data-aos="fade">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <h3 className="titulo text-white mb-0">Conoce nuestras Preguntas Frecuentes acerca del Injerto Capilar</h3>
                            </div>
                            <div className="col-12 col-md-6 text-center text-md-right">
                                <Link to="/faqs" className="btn btn-rojo" style={{maxWidth: "240px"}}>Ver todas</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Servicio completo para foráneos */}
                <div className="section-5">
                    <div className="container" data-aos="fade">
                        <div className="card border-0 rounded-0 shadow mx-auto">
                            <div className="card-header bg-white border-0 pb-0">
                                <h4 className="mb-0">Servicio completo para foráneos</h4>
                            </div>
                            <div className="card-body">
                                <p className="mb-0 font-weight-bold">El paquete incluye:</p>
                                <p className="mb-0 font-weight-bold">· Estancia</p>
                                <p className="mb-0 font-weight-bold">· Traslado aeropuerto - hotel - hospital - hotel - aeropuerto</p>
                                <p className="mb-3 font-weight-bold">· Procedimiento de injerto</p>
                                <p className="mb-0">Contáctanos para más detalles</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Nuestros clientes */}
                <div className="section-6">
                    <div className="container" data-aos="fade">
                        <h3 className="titulo text-center m40">Nuestros Clientes</h3>
                        <div className="row justify-content-around m40">
                            <div className="col-12 col-md-6 col-lg-5 m30 mb-md-0 text-center">
                                <div className="d-inline-block">
                                    <SliderBeforeAfter
                                        img_before={before_1}
                                        img_after={after_1}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 text-center">
                                <div className="d-inline-block">
                                    <SliderBeforeAfter
                                        img_before={before_2}
                                        img_after={after_2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Injerto;