import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Cotiza from './components/Cotiza';
import Contacto from './components/Contacto';
import SliderBeforeAfter from './components/SliderBeforeAfter';

import SwiperCore, { Autoplay } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

import $ from 'jquery';
import '../css/slider_before_after.css';
import '../scss/web/inicio.scss';

import slide_1 from '../img/home/slider01.jpg';
import slide_2 from '../img/home/slider02.jpg';
import portada_video from '../img/home/portada_video.jpg';
import servicios_icon_1 from '../img/home/servicios/1.svg';
import servicios_icon_2 from '../img/home/servicios/2.svg';
import servicios_icon_3 from '../img/home/servicios/3.svg';
import before_1 from '../img/blog/clientes/1.jpg';
import after_1 from '../img/blog/clientes/2.jpg';
import before_2 from '../img/blog/clientes/3.jpg';
import after_2 from '../img/blog/clientes/4.jpg';

SwiperCore.use([Autoplay]);
window.jQuery = $;
require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

class Home extends Component {
    render () {
        return (
            <section className="section-home">
                {/* Slide */}
                <div className="section-1">
                    <div className="container-fluid w16 px-0" data-aos="fade">
                        <Swiper
                            spaceBetween={0}
                            speed={1500}
                            effect={'fade'}
                            // autoplay={{
                            //     disableOnInteraction: false
                            // }}
                            loop={true}
                        >
                            <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_1})`}}><img src={slide_1} alt="base" /></div> </SwiperSlide>
                            {/* <SwiperSlide> <div className="bg" style={{backgroundImage: `url(${slide_2})`}}><img src={slide_1} alt="base" /></div> </SwiperSlide> */}
                        </Swiper>
                    </div>
                </div>

                {/* Informacion inicial */}
                <div className="section-2">
                    <div className="container">
                        <div className="row justify-content-between align-items-center" data-aos="fade">
                            <div className="col-12 col-md-6 col-lg-5 m30 mb-md-0 text-center text-md-left">
                                <h2 className="mb-0">Hairclinic existe por una razón solamente: devolverte la sensación y confianza de ser tu mismo.</h2>
                            </div>
                            <div className="col-12 col-md-6">
                                <h5 className="mb-2">Nuestra meta</h5>
                                <p className="mb-0">Detrás de esta meta encontrarás un compromiso con la calidad en la experiencia, la tecnología y la técnica, y con la naturalidad en los resultados. Sabemos que sentirte cómodo y recuperar el cabello es más que solo un proceso físico, es una forma de sentirte tu mismo de nuevo y por esto nuestro trato y profesionalismo irán siempre de la mano de la atención más humana.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Video */}
                <div className="section-3">
                    <div className="container-fluid w13">
                        <img src={portada_video} alt="portada de video" className="w-max-100 position-relative" style={{zIndex: 1, cursor: "pointer"}} data-fancybox data-src="https://vimeo.com/571281828" data-aos="fade" />
                    </div>
                </div>

                {/* Servicios */}
                <div className="section-4">
                    <div className="container" data-aos="fade">
                        <h3 className="titulo text-center m40 m-lg-60">Servicios</h3>
                        <div className="row">
                            <div className="col-12 col-md-4 d-flex align-items-center justify-content-center m30 mb-md-0"><img src={servicios_icon_1} alt="transplante" className="mr-3" /> <span className="tlo">Transplante de cabello</span></div>
                            <div className="col-12 col-md-4 d-flex align-items-center justify-content-center m30 mb-md-0"><img src={servicios_icon_2} alt="transplante" className="mr-3" /> <span className="tlo">Transplante de barba</span></div>
                            <div className="col-12 col-md-4 d-flex align-items-center justify-content-center"><img src={servicios_icon_3} alt="transplante" className="mr-3" /> <span className="tlo">Transplante de cejas</span></div>
                        </div>
                    </div>
                </div>

                {/* Formulario de cotizacion */}
                <Cotiza bg={'all-black'}/>

                {/* Clientes */}
                <div className="section-5">
                    <div className="container" data-aos="fade">
                        <h3 className="titulo text-center m40">Nuestros Clientes</h3>
                        <div className="row justify-content-around m40">
                            <div className="col-12 col-md-6 col-lg-5 m30 mb-md-0 text-center">
                                <div className="d-inline-block">
                                    <SliderBeforeAfter
                                        img_before={before_1}
                                        img_after={after_1}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 text-center">
                                <div className="d-inline-block">
                                    <SliderBeforeAfter
                                        img_before={before_2}
                                        img_after={after_2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="/clientes" className="btn">Ver todos</Link>
                        </div>
                    </div>
                </div>

                {/* Preguntas frecuentes */}
                <div className="section-6">
                    <div className="container" data-aos="fade">
                        <h3 className="text-center titulo m40">Preguntas frecuentes</h3>

                        <div className="text-left m30 m-md-60 m-lg-90">
                            <h5>¿Es doloroso?</h5>
                            <p>El proceso se realiza con anestesia local por lo cual las molestias son mínimas. Aplicar la anestesia podría ser lo más incómodo pero es momentáneo.</p>
                        </div>
                        <div className="text-right m30 m-md-60 m-lg-90">
                            <h5>¿Cuanto tarda el proceso?</h5>
                            <p>El proceso desde que se entra al consultorio hasta que se sale puede ser de 8 a 10 horas dependiendo del tamaño del área a tratar.</p>
                        </div>
                        <div className="text-left m30 m-md-60 m-lg-90">
                            <h5>¿Cual técnica me conviene?</h5>
                            <p>Dependiendo del área a cubrir, si es grande o pequeña, y de la calidad de la zona donadora, se definirá la técnica ideal. Actualmente manejamos las técnicas FUT, FUSS y combinadas, de las cuales puedes leer más aquí.</p>
                        </div>
                        <div className="text-center">
                            <Link to="/faqs" className="btn">Ver todos</Link>
                        </div>
                    </div>
                </div>

                {/* Formulario de contacto */}
                <Contacto />
            </section>
        );
    }
}

export default Home;