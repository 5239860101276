import React, {Component} from 'react';

class Equipo extends Component {
    convertToSlug(Text)
    {
        return Text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    }

    render() {
        return(
            <div className="col-12 col-sm-6 col-md-4 col-xl-3 m30 m-lg-50">
                <div className="card border-0">
                    <div className="card-body text-center">
                        <img src={this.props.foto} alt={this.convertToSlug('Hola mundo')} className="mb-2 w-max-100" />
                        <h5 className="tlo mb-2">{this.props.nombre}</h5>
                        <p className="mb-0">{this.props.puesto}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Equipo;