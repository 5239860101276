import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'aos/dist/aos.css';
import AOS from'aos';

import Header from './web/components/Header';
import Footer from './web/components/Footer';

import Home from './web/Home';
import Injerto from './web/Injerto';
import Nosotros from './web/Nosotros';
import Cotiza from './web/Cotiza';
import Clientes from './web/Clientes';
import Faqs from './web/Faqs';
import Blog from './web/Blog';
import BlogDetalle from './web/BlogDetalle';

function App() {
	AOS.init({
		duration: 1000,
		delay: 100
	});

	return (
		<Router>
			<div>
				<Header />
				<Switch>
					<Route path="/" exact>
						<Home />
					</Route>
					<Route path="/injerto" exact>
						<Injerto />
					</Route>
					<Route path="/nosotros" exact>
						<Nosotros />
					</Route>
					<Route path="/cotiza" exact>
						<Cotiza />
					</Route>
					<Route path="/blog" exact>
						<Blog />
					</Route>
					<Route path="/blog/detalle/:id/:url" exact>
						<BlogDetalle />
					</Route>
					<Route path="/clientes" exact>
						<Clientes />
					</Route>
					<Route path="/faqs" exact>
						<Faqs />
					</Route>
				</Switch>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
