import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import logo from '../../img/footer/logo.svg';
import logo_1 from '../../img/footer/02.png';
import logo_2 from '../../img/footer/03.png';

class Footer extends Component {
    render () {
        return (
            <footer>
                <div className="container-fluid w16">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 m30 mb-lg-0"><img src={logo} alt="HairClinic" className="w-max-100" /></div>
                        <div className="col-12 col-md-6 col-lg-3 m30 mb-lg-0">
                            <p className="mb-0 text-white">T. Consultorio: (999) 406-0596</p>
                            <p className="mb-0 text-white">Whatsapp: (999) 223-0788</p>
                            <p className="mb-0 text-white">C. hairclinic@amederi.com</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 m30 mb-lg-0">
                            <ul className="list-unstyled">
                                <li className="text-white"><Link to="/">Inicio</Link></li>
                                <li className="text-white"><Link to="/">Injerto Capilar</Link></li>
                                <li className="text-white"><Link to="/">Nosotros</Link></li>
                                <li className="text-white"><Link to="/">Cotiza</Link></li>
                                <li className="text-white"><Link to="/">Blog</Link></li>
                                <li className="text-white"><Link to="/">Preguntas frecuentes</Link></li>
                                <li className="text-white"><Link to="/">Clientes</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 m30 mb-lg-0 text-center text-md-rigth">
                            <img src={logo_1} alt="" className="w-max-100" />
                            <img src={logo_2} alt="" className="w-max-100" />
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;