import React, {Component} from 'react';

class Faq extends Component {
    render() {
        return(
            <div className={`m40 m-lg-80 ${this.props.align}`} key={this.props.id}>
                <h5 className="titulo-small mb-2">{this.props.titulo}</h5>
                <p className="mb-0">{this.props.info}</p>
            </div>
        );
    }
}

export default Faq;