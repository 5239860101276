import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class CardBlog extends Component {
    convertToSlug(Text)
    {
        return Text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    }

    render() {
        return(
            <div className="card border-0 rounded-0 card-blog h-100">
                {console.log(this.props.portada)}
                <img src={this.props.portada} alt={this.convertToSlug(this.props.titulo)} />
                <div className="card-body">
                    <p className="mb-2 text-white"><small>{this.props.fecha}</small></p>
                    <h5 className="titulo-small">{this.props.titulo}</h5>
                    <p className="text-white">{this.props.descripcion_corta}</p>
                    <a href={this.props.url}>Leer más</a>
                </div>
            </div>
        );
    }
}

export default CardBlog;